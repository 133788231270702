'use client'
import { errorData } from '@/api/error'
import { useSendResetPasswordEmail } from '@/api/hooks/useUserApi'
import OTPAuthModal from '@/components/modal/OTPAuthModal'
import toast from '@/lib/toast'
import { useModalStore } from '@/lib/useModalStore'
import { Button, Flex, Form, Input, Modal, Typography } from 'antd'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import * as jwt from 'jsonwebtoken'
import { getSession, signIn } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
import React, { useEffect, useState } from 'react'

type Error = {
  [key: string]: string
}

const userInfoError: Error = {
  // DATA_TYPE_EXCEPTION: '존재하지 않는 계정입니다.',
  NOT_FOUND_USER: '존재하지 않는 계정입니다.',
  WRONG_PASSWORD: '비밀번호가 일치하지 않습니다. (영문자/숫자/특수문자 혼합)',
}

const statusError: Error = {
  CANNOT_LOGIN_MANAGER_ROLE: '사장님 계정으로 오피스 접근이 불가능합니다.',
  CANNOT_LOGIN_STAFF_ROLE:
    '승인 대기 상태의 계정입니다.\n관리자에게 문의해주세요.',
  PENDING_STATUS_USER: '승인 대기 상태의 계정입니다.\n관리자에게 문의해주세요.',
  RETURN_STATUS_USER: '가입신청이 반려되었습니다.\n담당자에게 문의해주세요.',
  DELETE_STATUS_USER: '존재하지 않는 계정입니다.',
  PAUSE_STATUS_USER: '비활성 상태의 계정입니다. 관리자에게 문의해주세요.',
}

export default function LoginForm({ style }: { style: any }) {
  const { Title, Text } = Typography
  const [form] = Form.useForm()
  const router = useRouter()
  const { openModal, closeModal } = useModalStore((state) => state)

  const searchParams = useSearchParams()

  // state
  const [disabledLoginButton, setDisabledLoginButton] = useState(true)
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState('')
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // API
  const [apiError, setApiError] = useState<any>()
  const sendResetPasswordEmail = useSendResetPasswordEmail()

  useEffect(() => {
    setCookie('otp-pass', 'false')
  }, [])

  useEffect(() => {
    if (!apiError?.errorCode) return

    const errorCode = apiError?.errorCode

    // 유저 정보(이메일, 비밀번호) 오류: 에러 메세지 노출
    form.setFields([
      {
        name: 'email',
        errors:
          ((errorCode === errorData.NOT_FOUND_USER.errorCode ||
            errorCode === errorData.DATA_TYPE_EXCEPTION.errorCode) && [
            userInfoError[errorData.NOT_FOUND_USER.errorCode],
          ]) ||
          undefined,
      },
      {
        name: 'password',
        errors:
          (errorCode === errorData.WRONG_PASSWORD.errorCode && [
            userInfoError[errorCode],
          ]) ||
          undefined,
      },
    ])

    if (
      ![
        errorData.NOT_FOUND_USER.errorCode,
        errorData.DATA_TYPE_EXCEPTION.errorCode,
        errorData.WRONG_PASSWORD.errorCode,
      ].includes(errorCode)
    ) {
      openModal({
        message: statusError[errorCode],
      })
    }
  }, [apiError])

  useEffect(() => {
    if (sendResetPasswordEmail.isError) {
      const errorCode = sendResetPasswordEmail.error?.errorCode
      setEmailValidation(
        (errorCode === errorData.NOT_FOUND_USER.errorCode &&
          '가입되지 않은 이메일입니다.') ||
          (errorCode === errorData.RETURN_STATUS_USER.errorCode &&
            '반려 상태의 계정입니다. 관리자에게 문의해 주세요.') ||
          (errorCode === errorData.PAUSE_STATUS_USER.errorCode &&
            '비활성 상태의 계정입니다. 관리자에게 문의해 주세요') ||
          (errorCode === errorData.OFFLINE_NETWORK.errorCode &&
            '발송에 실패하였습니다. 다시 시도해주세요.') ||
          '',
      )
    }
  }, [sendResetPasswordEmail.error])

  useEffect(() => {
    if (sendResetPasswordEmail.isSuccess) {
      toast.success('비밀번호 재설정 링크가 발송되었습니다.')
      initResetPasswordModal()
    }
  }, [sendResetPasswordEmail.isSuccess])

  useEffect(() => {
    if (!resetPasswordModalOpen) {
      initResetPasswordModal()
    }
  }, [resetPasswordModalOpen])

  useEffect(() => {
    if (getCookie('sessionFinished') === 'true') {
      deleteCookie('sessionFinished')
    }
  }, [getCookie('sessionFinished')])

  /**
   * @description form submit callback 함수
   */
  async function onSubmit() {
    setIsLoading(true)
    const email = form.getFieldValue('email').trim()
    const password = form.getFieldValue('password').trim()

    const res = await signIn('tabling-office-credentials', {
      email,
      password,
      redirect: false,
      callbackUrl: searchParams?.get('from') || '/account',
    })

    if (res?.error) {
      setApiError(JSON.parse(res.error))
      setIsLoading(false)
    }

    if (!res?.error && res?.ok) {
      const session = await getSession()
      const decodedToken: any = jwt.decode(session?.accessToken || '')
      if (['ADMIN', 'SUPER'].includes(decodedToken.role)) {
        // OTP 인증절차 추가
        setCookie('token', session?.accessToken)
        openModal({
          key: 'otp-auth-modal',
          component: (
            <OTPAuthModal
              key="otp-auth-modal"
              onClose={() => {
                closeModal()
                setIsLoading(false)
              }}
              onSuccess={() => {
                closeModal()
                router.push(res?.url || '/account')
              }}
            />
          ),
        })
        return
      }
      router.push(res?.url || '/account')
    }
  }

  function onChange(changedFields: any) {
    const field = changedFields[0].name
    const value = changedFields[0].value
    form.setFieldValue(field, value)
    setDisabledLoginButton(
      !form.getFieldsValue().email || !form.getFieldsValue().password,
    )
  }

  function onSubmitEmail() {
    sendResetPasswordEmail.mutate({ email })
  }

  function resetPasswordBtnDisabled() {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return !regexEmail.test(email)
  }

  function initResetPasswordModal() {
    setResetPasswordModalOpen(false)
    setEmail('')
    setEmailValidation('')
    sendResetPasswordEmail.reset()
  }

  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className={style.form}
        onFieldsChange={onChange}
      >
        <div className={style['input-container']}>
          <Form.Item name="email" label="이메일" className={style['input']}>
            <Input
              placeholder="user@tabling.co.kr"
              onBlur={(e: any) => {
                form.setFieldValue('email', e.target.value.trim())
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="비밀번호"
            className={style['input']}
          >
            <Input.Password placeholder="영문, 숫자, 특수문자 혼합 8자 이상" />
          </Form.Item>
        </div>
        <div className={style['button-container']}>
          <Form.Item className={style['button']}>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              disabled={disabledLoginButton}
              onClick={onSubmit}
              loading={isLoading}
            >
              로그인
            </Button>
          </Form.Item>
          <Form.Item className={style['button']}>
            <Button href="/signup" htmlType="reset" block size="large">
              회원가입
            </Button>
          </Form.Item>
        </div>
        <div className={style['password-container']}>
          <Text className={style['info-text']}>비밀번호를 잊으셨나요?</Text>
          <Button
            className={style['password-reset-button']}
            type="link"
            onClick={() => setResetPasswordModalOpen(true)}
          >
            비밀번호 재설정하기
          </Button>
        </div>
      </Form>
      <Modal
        open={resetPasswordModalOpen}
        footer={null}
        onCancel={() => setResetPasswordModalOpen(false)}
      >
        <Title level={3}>비밀번호 재설정</Title>
        <Flex vertical gap={16}>
          <div>
            가입한 이메일 주소로 비밀번호 재설정 링크가 발송됩니다.
            <br />
            발송된 링크를 통해 비밀번호를 변경해주세요.
          </div>
          <Flex vertical>
            <p style={{ fontWeight: 600 }}>가입 이메일</p>
            <Flex gap={4} style={{ margin: '8px 0 2px' }}>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => {
                  if (sendResetPasswordEmail.isSuccess) return // enter로 submit시, onBlur 이벤트가 api 통신 성공 및 팝업 닫힌 후에 발생해 추가했습니다.
                  setEmail(email.trim())
                }}
                onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (!resetPasswordBtnDisabled()) {
                    onSubmitEmail()
                  }
                }}
                placeholder="가입한 이메일을 입력해주세요."
              />
              <Button
                type="primary"
                onClick={onSubmitEmail}
                loading={sendResetPasswordEmail.isPending}
                disabled={resetPasswordBtnDisabled()}
              >
                링크발송
              </Button>
            </Flex>
            <Text style={{ color: '#ff4d4f' }}>{emailValidation}</Text>
          </Flex>
        </Flex>
      </Modal>
    </div>
  )
}
